$opc_update: true;
$welcome15_offer: true;
$appt_booking: true;
$ano-bold_regular-font: 'ano-bold_regular', '微軟正黑體', 'Microsoft JhengHei', Helvetica, Arial,
  Sans-Serif;
$roboto-mono_regular-font: 'roboto-mono_regular', '微軟正黑體', 'Microsoft JhengHei', Helvetica, Arial, Sans-Serif;
$tstar-font: 'tstar_mono_roundregular', '微軟正黑體', 'Microsoft JhengHei', Helvetica, Arial, Sans-Serif;
$password_strengthen: true;

// 2020 styling refresh.
$cr20: true;

// 2021 styling refresh.
$cr21: true;

$gnav_update: true;

// 2021 styling refresh for SPP.
$cr21_spp: false;

// 2021 styling refresh for MPP.
$cr21_mpp: false;